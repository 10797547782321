<template>
  <page fluid="lg" title="Crear causas">
    <formulario :causa="causa" @submit="insertar"></formulario>
  </page>
</template>

<script>
import Formulario from "./Formulario.vue";
import * as apiCausas from "@/services/causas.js";

export default {
  components: { Formulario },
  data() {
    return {
      causa: apiCausas.crear(),
    };
  },
  methods: {
    async insertar() {
      let ok = await apiCausas.insertar(this.causa);
      if (ok) {
        this.$emit("userMessage", "Se ha creado la causa");
        this.$router.push({ path: "/administracion/causas" });
      }
    },
  },
};
</script>
