<template>
  <b-form @submit.prevent="submit" autocomplete="off" class="formulario_indpredictivos">
    <b-form-group label="Nombre">
      <b-form-input
        autofocus
        required
        type="text"
        v-model="causa.nombre"
        placeholder="Nombre de la causa"
      ></b-form-input>
    </b-form-group>
    <b-form-group label="Estado">
      <b-form-checkbox v-model="causa.activa" switch value="1" unchecked-value="0">
        <b v-if="causa.activa == true">Activa</b>
        <b v-else>Inactiva</b>
      </b-form-checkbox>
    </b-form-group>
    <botonera-edicion url-cancelar="/administracion/causas"> </botonera-edicion>
  </b-form>
</template>
<script>
import Vue from "vue";
export default {
  props: {
    causa: Object,
  },
  methods: {
    submit() {
      this.$emit("submit");
    },
  },
};
</script>
